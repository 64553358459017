import React from 'react'


import Navigationbar from "./Navigationbar";
import Footer from './Footer'

const Layout = ({children}) => {
    return (
      <div className="bg-gray-white">
        <div className='absolute w-full z-50 '>
          <Navigationbar></Navigationbar>
        </div>

        {children}
        <Footer></Footer>
      </div>
    );
}

export default Layout
